<template>
  <div class="d-flex flex-column flex-lg-row align-items-center w-100">
    <div>
      <img :src="img" style="height: 10rem" class="m-3 ml-4 mr-4" />
    </div>
    <div class="w-100">
      <h5 class="pl-3">{{ title }}</h5>
      <p class="text-justify pl-3 pr-3" style="min-height: 8rem">{{ text }}</p>
      <div class="d-flex justify-content-end pr-3 pb-3">
        <div
          class="btn btn-outline-primary p-3 pl-4 pr-4 mr-3"
          :class="{ active: value }"
          @click="$emit('input', true)"
        >
          Oui
        </div>
        <div class="btn btn-outline-primary p-3 pl-4 pr-4" :class="{ active: !value }" @click="$emit('input', false)">
          Non
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["title", "img", "text", "value"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
