<template>
  <Modal :id="'modal_' + adr.id" :title="'Analyse des risques n°' + adr.id" btn="Ouvrir" @modal-open="tab = 0">
    <template v-slot:title>
      <div class="w-100 p-2 rounded mb-1 mt-1" v-background-1>
        <div v-if="adr.libelle">
          {{ adr.libelle }}
        </div>
        <div v-if="!adr.libelle" class="text-primary">Nouvelle Analyse des risques à compléter</div>
      </div>
    </template>
    <template v-slot:form>
      <div class="pl-3 pr-3">
        <!-- IDENTIFICATION -->
        <div v-if="tab == 0">
          <p>
            La réalisation d'une analyse des risques est une étape obligatoire pour l'ensemble des interventions sur
            site client. Ce module vous permet de générer rapidement une ADR à partir d'un questionnaire simple et / ou
            d'utiliser une ADR générique pré-remplie. Vous pouvez également chargé les données d'une ADR générique et
            puis apporter quelques changement. Pour commencer indiquer le libellé de l'analyse des risques
          </p>
          <base-form-row row>
            <BaseInput v-model="adr.libelle" inputText="Libellé" :errors="feedback.libelle"></BaseInput>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Adr Générique"
              v-model.number="adr_id"
              :options="generiques"
              :errors="feedback.adr_id"
              field="libelle"
            ></base-select>
          </base-form-row>
          <div class="text-success" v-if="adr_generique">
            <small>{{ adr_generique }}</small>
          </div>
        </div>

        <!-- Q1 -->
        <div class="" v-if="tab < 21">
          <prepa-adr-item-slide
            v-if="tab == 1"
            v-model="adr.q1"
            title="Sûreté des installations"
            text="Existe-t-il une activité (AIP) pouvant générer un risque pour les installations (intervention sur ou à proximité de matériel(s) EIPS – ou équivalent hors EDF). Cela comprend le risque FME."
            img="/images/adr_general.svg"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 2"
            title="Environnement"
            text="Existe-t-il une activité pouvant générer des rejets (effluents) ou déchets (hors papier, bureautique) ?"
            img="/images/adr_environnement.svg"
            v-model="adr.q2"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 3"
            title="Circulation et déplacement"
            text="Risque de chute de plain-pied, de heurt ou collision. Ce risque est pratiquement toujours présent."
            img="/images/adr_plain_pied.svg"
            v-model="adr.q3"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 4"
            title="A proximité d’équipement en fonctionnement"
            text="L'intervention est réalisée à proximité d'équipement dangereux (mécanique, sous pression...) en fonctionnement."
            img="/images/adr_meca.svg"
            v-model="adr.q4"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 5"
            title="Travaux en hauteur"
            text="Les chutes de hauteur représentent la 2e cause d’accidents mortels liés au travail. Indiquez si l'intervention est réalisée (même en partie) sur des toitures, charpentes, terrasses de bâtiments, à des zones en surélévation : échelles, escaliers, passerelles ou d’autres équipements (échafaudage…) ou à proximité d’une tranchée, d’une fouille..."
            img="/images/adr_chute.svg"
            v-model="adr.q5"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 6"
            title="Risque routier"
            text="L'intervention implique des déplacements en véhicule (transport des personnes et/ou du matériel). Le risque routier est à l’origine de plus de 20 % des accidents mortels du travail."
            img="/images/adr_route.svg"
            v-model="adr.q6"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 7"
            title="Ambiances thermiques"
            text="Ces ambiances thermiques peuvent avoir de graves effets sur la santé et augmenter les risques d’accidents du travail. Indiquez si l'intervention implique du travail sous des températures supérieure à 30°C ou inférieure à 10°C."
            img="/images/adr_thermique.svg"
            v-model="adr.q7"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 8"
            title="Manutention manuelle"
            text="L'intervention implique le transport de charges par les collaborateurs. On entend par manutention manuelle, toute opération de transport ou de soutien d'une charge, dont le levage, la pose, la poussée, la traction, le port ou le déplacement, qui exige l'effort physique d'un ou de plusieurs travailleurs."
            img="/images/adr_general.svg"
            v-model="adr.q8"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 9"
            title="Bruit"
            text="On considère que l’ouïe est en danger à partir d’un niveau de 80 décibels durant une journée de travail de 8 heures. Si le niveau est extrêmement élevé (supérieur à 130 décibels), toute exposition, même de très courte durée, est dangereuse. Indiquez si l'intervention est concernée par ce risque."
            img="/images/adr_bruit.svg"
            v-model="adr.q9"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 10"
            title="Électrique"
            text="Le risque électrique comprend le risque de contact, direct ou non, avec une pièce nue sous tension, le risque de court-circuit, et le risque d’arc électrique. Ses conséquences sont l’électrisation, l’électrocution, l’incendie, l’explosion… Les collaborateurs seront ils potentiellement dans une zone proche d'équipements sous tension ?"
            img="/images/adr_elec.svg"
            v-model="adr.q10"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 11"
            title="Incendie"
            text="Indiquez si l'intervention nécessite l'utilisation d'outillages source d’inflammation (meulage, soudage...) ou en présence de carburant / combustibles (papier, bois, carton, plastique, solvant pétrolier, essence, vernis, dégraissant, huile, gaz de ville (méthane), butane, propane, acétylène...)."
            img="/images/adr_feu.svg"
            v-model="adr.q11"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 12"
            title="Explosion, ATEX"
            text="Indiquez si l'intervention est réalisée dans une zone où peuvent se former des atmosphères explosives (zone ATEX). Ce zonage permet, par la suite, de réaliser l’adéquation de l’ensemble du matériel, électrique et non-électrique, avec le type de zone, afin qu’il ne constitue pas la source d’inflammation potentielle."
            img="/images/adr_atex.svg"
            v-model="adr.q12"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 13"
            title="Conditions de travail particulières"
            text="Le travail de nuit et le travail posté sont des horaires dits « atypiques » et peuvent engendrer des risques pour la santé. Indiquez si cette intervention nécessite une organisation en 2x8 ou 3x8."
            img="/images/adr_general.svg"
            v-model="adr.q13"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 14"
            title="Rayonnements ionisants : irradiation"
            text="Vous réalisez une opération en zone surveillée et/ou contrôlée. Indiquez ici s'il y aura exposition externe sans contact cutané : la source est située à distance de l’organisme. Sont surtout à prendre en compte les rayonnements gamma, X et neutroniques."
            img="/images/adr_rp.svg"
            v-model="adr.q14"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 15"
            title="Rayonnements ionisants : contamination"
            text="Indiquez si l'intervention est réalisée dans une zone contaminée et s'il existe un risque de contamination des personnes ou du matériel (exposition externe par contact cutané ou par inhalation / ingestion de substances radioactives)."
            img="/images/adr_rp.svg"
            v-model="adr.q15"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 16"
            title="Rayonnements ionisants : sources scellées"
            text="L'intervention nécessite l'utilisation d'une source ou d'un générateur à rayon X. Dans ce cas, Arkadia génère le risque d'exposition aux rayonnements ionisants."
            img="/images/adr_rp.svg"
            v-model="adr.q16"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 17"
            title="Risque chimique"
            text="Indiquez si l'intervention nécessite l'utilisation de produits chimiques dangereux et/ou CMR (produit de ressuage ou de développement par exemple). A noter, Arkadia n'intervient pas dans une zone à risque amiante."
            img="/images/adr_chimie.svg"
            v-model="adr.q17"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 18"
            title="Espace confiné"
            text="Vous intervenez dans un espace confiné c'est à dire un volume totalement ou partiellement fermé (bâtiment, ouvrage, équipement, installation…) au sein duquel l’atmosphère peut présenter des risques pour la santé et la sécurité des personnes qui y pénètrent. Ces risques sont souvent la cause d’accidents graves ou mortels."
            img="/images/adr_general.svg"
            v-model="adr.q18"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 19"
            title="Travaux sur écran"
            text="Travailler intensivement devant un écran de visualisation peut engendrer des troubles de la santé tels que fatigue visuelle, troubles musculosquelettiques et stress. Indiquez si l'opération implique un travail prolongé devant un écran."
            img="/images/adr_ecran.svg"
            v-model="adr.q19"
          ></prepa-adr-item-slide>
          <prepa-adr-item-slide
            v-if="tab == 20"
            title="Biologique"
            text="Indiquez s'il y a un risque de contamination des collaborateurs aux agents biologiques (bactéries, champignons, virus…). Cela comprend la COVID19 et les autres agents pathogènes identifié dans certains vestiaires par exemple."
            img="/images/adr_bio.svg"
            v-model="adr.q20"
          ></prepa-adr-item-slide>
          <div class="d-flex justify-content-between mt-3 mb-3">
            <div class="text-danger cursor-pointer" v-if="tab == 0" @click="deleteAdr">Supprimer</div>
            <div class="text-primary cursor-pointer" v-if="tab > 0" @click="prev">Précédent</div>
            <div class="text-primary cursor-pointer" @click="next">
              Suivant <span class="badge badge-primary">{{ tab }}/21</span>
            </div>
          </div>
        </div>

        <!-- END -->
        <div v-if="tab == 21">
          <p>Vous pouvez ajouter ici un commentaire</p>
          <base-form-row row>
            <base-input-area v-model="adr.commentaire" text="Commentaire(s)"></base-input-area>
          </base-form-row>
          <div class="d-flex justify-content-end">
            <BaseButton
              class="btn btn-primary"
              v-tooltip="'Enregistrer'"
              @click="updateAdr"
              :loading="loading"
              text="Enregistrer"
              icon="save"
            ></BaseButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "@/components/bases/Modal2.vue";
//import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
//import Checkbox from "@/components/bases/Checkbox.vue";
import BaseInput from "@/components/bases/Input.vue";
import PrepaAdrItemSlide from "@/components/affaires/PrepaAdrItemSlide.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    Modal,
    BaseFormRow,
    BaseInputArea,
    BaseInput,
    PrepaAdrItemSlide,
    BaseSelect,
    BaseButton,
  },
  props: ["adr", "generiques"],
  data() {
    return {
      loading: false,
      feedback: {},
      tab: 0,
      adr_id: null,
      adr_generique: "",
    };
  },
  computed: {},
  methods: {
    updateAdr: function () {
      this.loading = true;
      this.$http
        .post("/adr", this.adr)
        //.then((response) => this.data.push(response.data))
        .finally(() => (this.loading = false));
    },
    deleteAdr: function () {
      this.loading = true;
      this.$http
        .delete("/adr/" + this.adr.id)
        .then(() => this.$emit("adrDeleted", this.adr.id))
        .finally(() => (this.loading = false));
    },
    next: function () {
      if (this.tab == 0 && this.adr_id !== null) {
        this.chargerAdrGenerique();
      }

      if (this.tab < 22) {
        if (this.adr.libelle) {
          this.tab++;
        } else {
          alert("Compléter le libellé de l'adr");
        }
      }
    },
    prev: function () {
      if (this.tab > 0) this.tab--;
    },
    chargerAdrGenerique: function () {
      const index = this.generiques.findIndex((x) => x.id === this.adr_id);
      this.adr.q1 = this.generiques[index].q1;
      this.adr.q2 = this.generiques[index].q2;
      this.adr.q3 = this.generiques[index].q3;
      this.adr.q4 = this.generiques[index].q4;
      this.adr.q5 = this.generiques[index].q5;
      this.adr.q6 = this.generiques[index].q6;
      this.adr.q7 = this.generiques[index].q7;
      this.adr.q8 = this.generiques[index].q8;
      this.adr.q9 = this.generiques[index].q9;
      this.adr.q10 = this.generiques[index].q10;
      this.adr.q11 = this.generiques[index].q11;
      this.adr.q12 = this.generiques[index].q12;
      this.adr.q13 = this.generiques[index].q13;
      this.adr.q14 = this.generiques[index].q14;
      this.adr.q15 = this.generiques[index].q15;
      this.adr.q16 = this.generiques[index].q16;
      this.adr.q17 = this.generiques[index].q17;
      this.adr.q18 = this.generiques[index].q18;
      this.adr.q19 = this.generiques[index].q19;
      this.adr.q20 = this.generiques[index].q20;
      this.adr_generique = this.generiques[index].libelle + " chargée";
    },
  },
  mounted() {},
};
</script>
