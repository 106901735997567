<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar title="Maitrise des risques" subtitle="Informations générales"></base-tool-bar>
    <div></div>
    <prepa-adr v-if="adr_activate" :affaire_id="$route.params.id"></prepa-adr>
    <div class="p-3 mb-3 rounded shadow-sm" v-background-3>
      <uploader
        v-if="id"
        id="document_affaire_adr"
        url="/upload/document"
        title="Ajouter ici vos ADR(s)"
        :file_system="'CRM'"
        :file_field="'adr'"
        :file_key="id"
      ></uploader>
    </div>
    <div class="p-3 mb-3 rounded shadow-sm" v-background-3>
      <uploader
        v-if="id"
        id="document_affaire_pdp"
        url="/upload/document"
        title="Ajouter ici vos PDP(s)"
        :file_system="'CRM'"
        :file_field="'adr_pdp'"
        :file_key="id"
      ></uploader>
    </div>
    <div class="p-3 mb-3 rounded shadow-sm" v-background-3 v-if="haveRole('admin') || haveRole('QSSER')">
      <base-form class="" :loading="loading" @click="updateAffaire">
        <div class="d-flex">
          <div class="mr-5 w-100">
            <Checkbox
              v-model="affaire_pdp_realise"
              text="PDP réalisé"
              :errors="feedback.affaire_pdp_realise"
            ></Checkbox>
            <Checkbox
              v-model="affaire_adr_realise"
              text="ADR réalisée"
              :errors="feedback.affaire_adr_realise"
            ></Checkbox>
            <Checkbox
              v-model="affaire_edp_realise"
              text="EDP réalisée"
              :errors="feedback.affaire_edp_realise"
            ></Checkbox>
            <Checkbox
              v-model="affaire_rtr_realise"
              text="RTR réalisé"
              :errors="feedback.affaire_rtr_realise"
            ></Checkbox>
          </div>
          <div class="w-100">
            <Checkbox v-model="affaire_pdp_na" text="PDP Non applicable" :errors="feedback.affaire_pdp_na"></Checkbox>
            <Checkbox v-model="affaire_adr_na" text="ADR Non applicable" :errors="feedback.affaire_adr_na"></Checkbox>
            <Checkbox v-model="affaire_edp_na" text="EDP Non applicable" :errors="feedback.affaire_edp_na"></Checkbox>
            <Checkbox v-model="affaire_rtr_na" text="RTR Non applicable" :errors="feedback.affaire_rtr_na"></Checkbox>
          </div>
        </div>
        <base-form-row class="mb-3">
          <base-input-area
            v-model="affaire_hse_commentaire"
            text="Commentaire QSSER"
            :errors="feedback.affaire_hse_commentaire"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </div>
  </div>
</template>
<script>
import BaseToolBar from "@/components/bases/ToolBar.vue";
import PrepaAdr from "@/components/affaires/PrepaAdr.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";

import Uploader from "@/components/bases/Uploader.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  components: {
    BaseToolBar,
    PrepaAdr,
    Uploader,
    Checkbox,
    BaseForm,
    BaseFormRow,
    BaseInputArea,
  },
  data() {
    return {
      adr_activate: false,
      loading: false,
      feedback: {},
    };
  },
  computed: {
    ...mapGetters({ haveRole: "user/haveRole" }),
    ...mapFields("affaires", [
      "affaire.id",
      "affaire.affaire_pdp_realise",
      "affaire.affaire_adr_realise",
      "affaire.affaire_edp_realise",
      "affaire.affaire_rtr_realise",
      "affaire.affaire_pdp_na",
      "affaire.affaire_adr_na",
      "affaire.affaire_edp_na",
      "affaire.affaire_rtr_na",
      "affaire.affaire_hse_commentaire",
    ]),
  },
  methods: {
    ...mapActions({
      update: "affaires/updateAffaire",
    }),
    updateAffaire: function () {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
