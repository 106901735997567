<template>
  <div class="w-100 mb-3 rounded pl-3 pr-3 rounded shadow-sm" v-background-3>
    <BaseToolBar title="Analyse des risques" subtitle="Liste des adrs associées à l'affaire">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une adr'"
        @click="createAdr()"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="mb-3">
      <small>
        Le processus affaire demande la réalisation systématique d'une analyse des risques (pour chaque intervention sur
        les sites client). Vous pouvez utiliser le formulaire en ligne à l'aide du bouton "ajouter" ci-dessous, le
        système propose des ADR génériques pour vous faire gagner du temps. Sinon vous pouvez utiliser le formulaire
        habituel. En complément il est impératif d'enregistrer votre plan de prévention.
      </small>
    </div>
    <div v-if="!loading" class="mb-3">
      <div v-for="adr in data" :key="adr.id">
        <prepa-adr-item :adr="adr" :generiques="generiques" @adrDeleted="deleteAdr"></prepa-adr-item>
      </div>
    </div>
    <div v-if="loading" class="m-3">Chargement...</div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";
import PrepaAdrItem from "@/components/affaires/PrepaAdrItem.vue";

export default {
  components: { BaseToolBar, BaseButton, PrepaAdrItem },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {},
      data: [],
      generiques: [],
    };
  },
  computed: {
    ...mapFields("affaires", ["affaire.id"]),
  },
  methods: {
    getAdrs: function () {
      this.loading = true;
      this.$http
        .get("/adr/" + this.id)
        .then((response) => (this.data = response.data))
        .finally(() => (this.loading = false));
    },

    getAdrsGeneriques: function () {
      this.loading = true;
      this.$http
        .get("/adr/generique")
        .then((response) => (this.generiques = response.data))
        .finally(() => (this.loading = false));
    },

    createAdr: function () {
      this.loading = true;
      this.$http
        .put("/adr", {
          affaire_id: this.id,
        })
        .then((response) => this.data.push(response.data))
        .finally(() => (this.loading = false));
    },
    deleteAdr: function (id) {
      const index = this.data.findIndex((x) => x.id === id);
      this.data.splice(index, 1);
    },
  },
  mounted() {
    this.getAdrs();
    this.getAdrsGeneriques();
  },
};
</script>
